import React, { useMemo, createContext, useState } from 'react';
import { searchFormFieldsListRentRoomOrBuildingDefault } from '../constants/rentProperty';
import { PropertySearchModeFullAccess } from '../types/propertySearchParams/mode';
import { AddressesCoordinateValue } from '../types/propertySearchParams/addressesCoordinate';
import { JgdcCode } from '../types/jgdcCode';
import { useSearchFormJgdcCodes } from '../hooks/useSearchFormJgdcCodes';
import { useBool } from '../hooks/util/useBool';
import { SearchablePropertySort } from '../types/propertySearchParams/sort';
import { RentRoomOrBuildingSearchSelected } from '../types/property/resouces/rentRoom';

export const RentBusinessPropertyGlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    rentBusinessPropertySearchSelected: RentRoomOrBuildingSearchSelected;
    setRentBusinessPropertySearchSelected: (
      searchSelected: RentRoomOrBuildingSearchSelected
    ) => void;
    searchFormJgdcCodes: ReadonlyArray<JgdcCode>;
    setFilteredSearchFormJgdcCodes: (
      jgdcCodes: ReadonlyArray<JgdcCode>
    ) => void;
    rentBusinessPropertyMode: PropertySearchModeFullAccess;
    setRentBusinessPropertyMode: (mode: PropertySearchModeFullAccess) => void;
    rentBusinessPropertyOffset: number;
    setRentBusinessPropertyOffset: (offset: number) => void;
    rentBusinessPropertySelectedSort: SearchablePropertySort;
    setRentBusinessPropertySelectedSort: (sort: SearchablePropertySort) => void;
    isRentBusinessPropertyListDrawerOpened: boolean;
    openRentBusinessPropertyListDrawer: () => void;
    closeRentBusinessPropertyListDrawer: () => void;
    rentBusinessPropertyAddressesCoordinate:
      | AddressesCoordinateValue
      | undefined;
    setRentBusinessPropertyAddressesCoordinate: (
      addressCoordinate: AddressesCoordinateValue | undefined
    ) => void;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

export const RentBusinessPropertyGlobalContextProvider: React.FC<Props> = ({
  children
}) => {
  const [
    rentBusinessPropertySearchSelected,
    setRentBusinessPropertySearchSelected
  ] = useState<RentRoomOrBuildingSearchSelected>(
    searchFormFieldsListRentRoomOrBuildingDefault
  );
  const [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes] =
    useSearchFormJgdcCodes();
  const [rentBusinessPropertyMode, setRentBusinessPropertyMode] =
    useState<PropertySearchModeFullAccess>('aggregate');
  const [rentBusinessPropertyOffset, setRentBusinessPropertyOffset] =
    useState<number>(1);
  const [
    rentBusinessPropertySelectedSort,
    setRentBusinessPropertySelectedSort
  ] = useState<SearchablePropertySort>('last_status_opened_at desc');
  const [
    isRentBusinessPropertyListDrawerOpened,
    openRentBusinessPropertyListDrawer,
    closeRentBusinessPropertyListDrawer
  ] = useBool(true);
  const [
    rentBusinessPropertyAddressesCoordinate,
    setRentBusinessPropertyAddressesCoordinate
  ] = useState<AddressesCoordinateValue | undefined>();

  const contextValue = useMemo(
    () => ({
      rentBusinessPropertySearchSelected,
      setRentBusinessPropertySearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      rentBusinessPropertyMode,
      setRentBusinessPropertyMode,
      rentBusinessPropertyOffset,
      setRentBusinessPropertyOffset,
      rentBusinessPropertySelectedSort,
      setRentBusinessPropertySelectedSort,
      isRentBusinessPropertyListDrawerOpened,
      openRentBusinessPropertyListDrawer,
      closeRentBusinessPropertyListDrawer,
      rentBusinessPropertyAddressesCoordinate,
      setRentBusinessPropertyAddressesCoordinate
    }),
    [
      rentBusinessPropertySearchSelected,
      setRentBusinessPropertySearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      rentBusinessPropertyMode,
      setRentBusinessPropertyMode,
      rentBusinessPropertyOffset,
      setRentBusinessPropertyOffset,
      rentBusinessPropertySelectedSort,
      setRentBusinessPropertySelectedSort,
      isRentBusinessPropertyListDrawerOpened,
      openRentBusinessPropertyListDrawer,
      closeRentBusinessPropertyListDrawer,
      rentBusinessPropertyAddressesCoordinate,
      setRentBusinessPropertyAddressesCoordinate
    ]
  );

  return (
    <RentBusinessPropertyGlobalContext.Provider value={contextValue}>
      {children}
    </RentBusinessPropertyGlobalContext.Provider>
  );
};
