// 建物ごと検索の建物表示件数
export const BuildingListLimit = 20;

export const additionalSearchBucketSize = {
  base: 5,
  additional: 50
};

// すべて表示するボタン押下前の初期表示件数
export const ShowAllCount = 3;
