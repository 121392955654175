import React from 'react';
import { includes } from 'lodash';
import { ItandiHeaderNotificationItem } from '@itandi/itandi-bb-ui';
import { Notification } from '../../../../types/notification';
import './NotificationItem.scss';

type Props = Readonly<{
  notifications: ReadonlyArray<Notification>;
  notificationsBadgeCount: number;
  toggleNotificationsBadgeCountTrigger: () => void;
}>;

export const NotificationItem: React.FC<Props> = ({
  notifications,
  notificationsBadgeCount,
  toggleNotificationsBadgeCountTrigger
}: Props) => {
  const handleNotificationItemClick = (
    notificationId: string,
    url: string
  ): void => {
    const notificationsIds = JSON.parse(
      localStorage.getItem('notificationsIds') || '[]'
    );
    if (!includes(notificationsIds, notificationId)) {
      localStorage.setItem(
        'notificationsIds',
        JSON.stringify([...(notificationsIds || []), notificationId])
      );
      toggleNotificationsBadgeCountTrigger();
    }
    window.open(url);
  };

  return (
    <ItandiHeaderNotificationItem
      newCount={notificationsBadgeCount}
      notifications={[...notifications]
        .reverse()
        .map((notification: Readonly<Notification>) => ({
          date: notification.date,
          newMark: !includes(
            JSON.parse(String(localStorage.getItem('notificationsIds'))),
            notification.notification_id
          ),
          onClick: (): void =>
            handleNotificationItemClick(
              notification.notification_id,
              notification.url
            ),
          title: notification.title
        }))}
    />
  );
};
