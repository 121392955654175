import { OptionTypeBase } from 'react-select/src/types';
import React from 'react';
import {
  checkBoxListOptionTv,
  checkBoxListOptionInternet,
  checkBoxListOptionBathroom,
  checkBoxListOptionBasinArea,
  checkBoxListOptionGas,
  checkBoxListOptionKitchen,
  checkBoxListOptionAirConditioning,
  checkBoxListOptionStorageSpace,
  checkBoxListOptionOutdoorSpace,
  checkBoxListOptionSecurity,
  checkBoxListOptionOther,
  checkBoxListOptionConditionsAttribute,
  checkBoxListOptionConditionsCorporation,
  checkBoxListOptionConditionsOther
} from './propertySearch';

// formのreset時の値（formの初期値）
export const searchFormFieldsListRentDefaultWithoutManagementCompanies = {
  'address:in': [],
  selectStations: [],
  'building_name:match': '',
  'room_number:match': '',
  'rent:gteq': '',
  'rent:lteq': '',
  totalRentCheck: '',
  'shikikin:eq': '',
  'reikin:eq': '',
  'ad_exists:eq': '',
  'offer_deal_type:in': [],
  'building_detail_type:in': [],
  'layout:in': [],
  'structure_type:in': [],
  'floor:eq': '',
  'floor:gteq': '',
  'station_walk_minutes:lteq': '',
  nearbyTrainStationWithBusMinutesCheck: '',
  'floor_area_amount:gteq': '',
  'floor_area_amount:lteq': '',
  'building_age:lteq': '',
  'option_id:all_in': [],
  'parking_exists:eq': '',
  'floor_height:gteq': '',
  'main_lighting_direction_type:in': []
};

export const searchFormFieldsListRentLandDefault = {
  'address:in': [],
  selectStations: [],
  selectManagementCompanies: [],
  'rent:gteq': '',
  'rent:lteq': '',
  'shikikin:eq': '',
  'reikin:eq': '',
  'ad_exists:eq': '',
  'offer_deal_type:in': [],
  'station_walk_minutes:lteq': '',
  nearbyTrainStationWithBusMinutesCheck: '',
  'land_area_amount:gteq': '',
  'land_area_amount:lteq': '',
  'land_right_type:eq': '',
  'land_optimal_usage_type:eq': '',
  'land_yoto_chiiki_type:in': [],
  'land_optimal_usage_type:in': [],
  'option_id:all_in': []
};

export const searchFormFieldsListRentParkingDefault = {
  'address:in': [],
  selectStations: [],
  selectManagementCompanies: [],
  'building_name:match': '',
  'rent:gteq': '',
  'rent:lteq': '',
  'shikikin:eq': '',
  'reikin:eq': '',
  'ad_exists:eq': '',
  'offer_deal_type:in': [],
  'station_walk_minutes:lteq': '',
  nearbyTrainStationWithBusMinutesCheck: '',
  'option_id:all_in': []
};

// formのreset時の値（formの初期値）
export const searchFormFieldsListRentRoomOrBuildingDefault = Object.assign(
  structuredClone(searchFormFieldsListRentDefaultWithoutManagementCompanies),
  {
    selectManagementCompanies: []
  }
);

export const checkBoxListRent: Readonly<OptionTypeBase> = {
  totalRentCheck: {
    true: '管理費・共益費込み'
  },
  'shikikin:eq': {
    0: '敷金なし'
  },
  'reikin:eq': {
    0: '礼金なし'
  }
};

export const checkBoxListKokokuhi = {
  'ad_exists:eq': {
    true: '広告費あり'
  }
};

export const checkBoxListRentOfferDealType = {
  'offer_deal_type:in': {
    lender: '貸主',
    placeholder: '代理',
    strict: '専属専任媒介',
    exclusive: '専任媒介',
    agent: '一般媒介'
  }
};

export const checkBoxListRentByCSSupportFee = {
  'ad_exists:eq': {
    true: 'CS support feeあり'
  }
};

export const checkBoxListRightType = {
  'land_right_type:eq': {
    ownership: '所有権'
  }
};

export const rentRoomSearchFormAccordionListFirst = [
  {
    panelNo: 1,
    panelName: 'テレビ',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionTv,
    icon: <i className="ri-tv-line" />
  },
  {
    panelNo: 2,
    panelName: 'インターネット',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionInternet,
    icon: <i className="ri-computer-line" />
  },
  {
    panelNo: 3,
    panelName: 'バス・トイレ',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionBathroom,
    icon: <span className="material-icons-sharp IconSvg">bathtub</span>
  },
  {
    panelNo: 4,
    panelName: '水回りその他',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionBasinArea,
    icon: (
      <span className="material-icons-sharp IconSvg">
        local_laundry_service
      </span>
    )
  },
  {
    panelNo: 5,
    panelName: 'ガス',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionGas,
    icon: <i className="ri-fire-line" />
  },
  {
    panelNo: 6,
    panelName: 'キッチン',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionKitchen,
    icon: <i className="ri-fridge-line" />
  },
  {
    panelNo: 7,
    panelName: '空調',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionAirConditioning,
    icon: <span className="material-icons-sharp IconSvg">autorenew</span>
  },
  {
    panelNo: 8,
    panelName: '収納',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionStorageSpace,
    icon: <i className="ri-archive-line" />
  },
  {
    panelNo: 9,
    panelName: '室外スペース',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionOutdoorSpace,
    icon: <span className="material-icons-sharp IconSvg">deck</span>
  },
  {
    panelNo: 10,
    panelName: 'セキュリティ',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionSecurity,
    icon: <i className="ri-door-lock-box-line" />
  },
  {
    panelNo: 11,
    panelName: '部屋情報その他',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionOther,
    icon: <i className="ri-record-circle-line" />
  }
];

export const rentRoomSearchFormAccordionListSecond = [
  {
    panelNo: 14,
    panelName: '入居条件（属性）',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionConditionsAttribute,
    icon: <i className="ri-user-settings-line" />
  },
  {
    panelNo: 15,
    panelName: '入居条件（法人）',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionConditionsCorporation,
    icon: <i className="ri-building-2-line" />
  },
  {
    panelNo: 16,
    panelName: '入居条件（その他）',
    checkboxName: 'option_id:all_in',
    checkBoxList: checkBoxListOptionConditionsOther,
    icon: <i className="ri-record-circle-line" />
  }
];
